<template>
    <section :class="open ? 'open': 'close'">
        <p class="item-1">
            <!-- <span class="icon">
                <img v-show="open" src='@/assets/sub.png' @click="open=!open">
                <img v-show="!open" src="@/assets/plug.png" @click="open=!open">
            </span> -->
            <svg-icon :icon-class="open ? 'close': 'open'" @click="open = !open"></svg-icon>
            <span class="title">
                <slot name="title"></slot>
            </span>
        </p>
        <div class="content">
            <div v-show="open">
                <slot name="content"></slot>
            </div>
            <el-divider></el-divider>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            open: false
        }
    },
}
</script>

<style scoped>
section.open,section.close{
    display: block;
    text-align: left;
}
.open{
    min-height: 100px;
    width: 630px;
    margin-top: 10px;
}
.close{
    position: relative;
    height: 60px;
    width: 630px;
    margin-top: 10px;
}
.item-1{
    vertical-align: top;
}
.title{
    color: #2E5082;
    font-size: 16px;

    /* position: absolute;
    left: 17px;
    top: -3px; */
    margin-left: 12px;
}
.content{
    /* position: absolute; 
    left: 0; */
    margin-top: 15px;
    text-align: left;
    text-indent: 25px;
    line-height: 20px;
}
.el-divider{
    width: 630px;
}
</style>